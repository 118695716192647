import { mapState } from 'vuex'

export default {
  data: () => ({
    selectedBalanceHolders: [],
    selectedServicePoints: [],
    selectedTerminals: [],
    selectedDevices: [],
    selectedDevicesTypes: [],
  }),

  computed: {
    ...mapState('dictionaries', ['connectedDevicesTypesDictionary']),

    balanceHolders() {
      return this.getBalanceHolderFilter
    },
    servicePoints() {
      if (!this.selectedBalanceHolders.length) return this.getServicePointFilter
      return this.getServicePointFilter.filter((item) => this.selectedBalanceHolders.indexOf(item.parent) >= 0)
    },
    terminals() {
      if (!this.selectedServicePoints.length && !this.selectedBalanceHolders.length) return this.getTerminalFilter
      const filteredServicePoints = this.selectedServicePoints.length
        ? this.selectedServicePoints
        : this.getServicePointFilter.filter((item) => this.selectedBalanceHolders.indexOf(item.parent) >= 0).map((sp) => sp.value)
      return this.getTerminalFilter.filter((item) => filteredServicePoints.indexOf(item.parent) >= 0)
    },
    devices() {
      if (
        !this.selectedTerminals.length &&
        !this.selectedServicePoints.length &&
        !this.selectedBalanceHolders.length &&
        !this.selectedDevicesTypes.length
      )
        return this.getDeviceFilter
      const filteredBalanceHolders = this.selectedBalanceHolders.length
        ? this.selectedBalanceHolders
        : this.balanceHolders.map((bh) => bh.value)
      const filteredServicePoints = this.selectedServicePoints.length
        ? this.selectedServicePoints
        : this.getServicePointFilter.filter((item) => filteredBalanceHolders.indexOf(item.parent) >= 0).map((sp) => sp.value)
      const filteredTerminals = this.selectedTerminals.length
        ? this.selectedTerminals
        : this.getTerminalFilter
            .filter((item) => filteredServicePoints.indexOf(item.parent) >= 0)
            .map((terminal) => terminal.value)
      let result = this.getDeviceFilter.filter((item) => filteredTerminals.indexOf(item.parent) >= 0)
      result = this.selectedDevicesTypes.length ? result.filter((item) => this.selectedDevicesTypes.includes(item.type)) : result
      return result
    },
    deviceTypes() {
      return this.connectedDevicesTypesDictionary
    },
    defaultFilterDefs() {
      return [
        {
          id: 0,
          label: 'Партнери',
          values: this.selectedBalanceHolders,
          sizes: { cols: '12', sm: '6', md: '2' },
          date: false,
          options: { list: this.balanceHolders, text: 'label', value: 'value' },
          disabled: !this.balanceHolders.length,
          show: true,
          applyFunc: this.setSelectedBalanceHolders,
        },
        {
          id: 1,
          label: 'Торгівельні точки',
          values: this.selectedServicePoints,
          sizes: { cols: '12', sm: '6', md: '4' },
          date: false,
          options: { list: this.servicePoints, text: 'label', value: 'value' },
          disabled: !this.servicePoints.length,
          show: true,
          applyFunc: this.setSelectedServicePoints,
        },
        {
          id: 2,
          label: 'Термінали',
          values: this.selectedTerminals,
          sizes: { cols: '12', sm: '4', md: '2' },
          date: false,
          options: { list: this.terminals, text: 'label', value: 'value' },
          disabled: !this.terminals,
          show: true,
          applyFunc: this.setSelectedTerminals,
        },
        {
          id: 3,
          label: 'Тип автомата',
          values: this.selectedDevicesTypes,
          sizes: { cols: '12', sm: '4', md: '2' },
          date: false,
          options: {
            list: this.deviceTypes,
            text: 'description',
            value: 'value',
          },
          disabled: false,
          show: true,
          applyFunc: this.setSelectedDevicesType,
        },
        {
          id: 4,
          label: 'Автомати',
          values: this.selectedDevices,
          sizes: { cols: '12', sm: '4', md: '2' },
          date: false,
          options: { list: this.devices, text: 'label', value: 'value' },
          disabled: !this.devices,
          show: true,
          applyFunc: this.setSelectedDevices,
        },
        {
          id: 5,
          sizes: { cols: '12', sm: '6', md: '4', lg: '3', xl: '2' },
          date: true,
          show: true,
          applyFunc: this.setSelectedDate,
        },
      ]
    },
  },

  methods: {
    setSelectedBalanceHolders(val) {
      this.selectedBalanceHolders = val
      let servicePoints = []
      for (const bh of val) {
        servicePoints.push(...this.servicePoints.filter((sp) => sp.parent === bh).map((sp) => sp.value))
      }
      let selectedServicePoints = []
      for (const sp of this.selectedServicePoints) {
        if (servicePoints.includes(sp)) {
          selectedServicePoints.push(sp)
        }
      }
      this.setSelectedServicePoints(selectedServicePoints)
    },
    setSelectedServicePoints(val) {
      this.selectedServicePoints = val
      let terminals = []
      for (const sp of val) {
        terminals.push(...this.terminals.filter((t) => t.parent === sp).map((t) => t.value))
      }
      let selectedTerminals = []
      for (const t of this.selectedTerminals) {
        if (terminals.includes(t)) {
          selectedTerminals.push(t)
        }
      }
      this.setSelectedTerminals(selectedTerminals)
    },
    setSelectedTerminals(val) {
      this.selectedTerminals = val
      let devices = []
      for (const t of val) {
        devices.push(...this.devices.filter((d) => d.parent === t).map((d) => d.value))
      }
      let selectedDevices = []
      for (const d of this.selectedDevices) {
        if (devices.includes(d)) {
          selectedDevices.push(d)
        }
      }
      this.setSelectedDevices(selectedDevices)
    },
    setSelectedDevicesType(val) {
      this.selectedDevicesTypes = val
      let devices = []
      for (const dt of val) {
        devices.push(...this.devices.filter((d) => d.parent === dt).map((d) => d.value))
      }
      let selectedDevices = []
      for (const d of this.selectedDevices) {
        if (devices.includes(d)) {
          selectedDevices.push(d)
        }
      }
      this.setSelectedDevices(selectedDevices)
    },
    setSelectedDevices(val) {
      this.selectedDevices = val
    },
    setSelectedDate(val) {
      this.selectedDates = val
    },
  },
}
